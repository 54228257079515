import xmlData from './kneauxLa.xml';
import XMLParser from 'react-xml-parser';

const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;

///Entertainment|Politics|Music-Art|Activism|Culture
const xml_data = `
<rss xmlns:itunes="http://www.itunes.com/dtds/podcast-1.0.dtd" xmlns:atom="http://www.w3.org/2005/Atom" xmlns:rawvoice="http://www.rawvoice.com/rawvoiceRssModule/" version="2.0">
    <channel>
        <title>The KneauxLA Podcast</title>
        <link>http://KneauxLA.com</link>
        <image>
            <url>https://kneauxla.com/podcasts/kneauxLa_podcast_icon.png</url>
            <title>The KneauxLA Podcast</title>
            <link>https://KneauxLA.com</link>
        </image>
        <description>
			The KneauxLA Podcast brings the cool, witty, hip and iconic voices of America’s most interesting city — out to the world. Hosts Bougage and Ru take the listeners on a journey to educate, inform, entertain and inspire.  Channeling the personality of New Orleans, LA — The KneauxLA Podcast covers art, culture food, music, politics, people, race, and much more — all from the perspectives of the unique individuals who make the city the cultural landmark it continues to be.  KneauxLA has something to say to the world.  Be in the KNOW!
        </description>
        <language>en-us</language>
        <copyright>KneauxLA.com copyright 2020</copyright>
        <atom:link href="https://kneauxla.com/podcasts/001/kneauxLa-001.xml" rel="self" type="application/rss+xml"/>
        <lastBuildDate>Fri, 27 Nov 2020 06:00:00 PDT</lastBuildDate>
        <itunes:author>KneauxLA.com</itunes:author>
        <itunes:summary>The KneauxLA Podcast brings the cool, witty, hip and iconic voices of America’s most interesting city — out to the world. Hosts Bougage and Ru take the listeners on a journey to educate, inform, entertain and inspire.  Channeling the personality of New Orleans, LA — The KneauxLA Podcast covers art, culture food, music, politics, people, race, and much more — all from the perspectives of the unique individuals who make the city the cultural landmark it continues to be.  KneauxLA has something to say to the world.  Be in the KNOW!</itunes:summary>
        <itunes:subtitle>Be in the KNOW</itunes:subtitle>
        <itunes:owner>
            <itunes:name>KneauxLA.com</itunes:name>
            <itunes:email>media@kneauxla.com</itunes:email>
        </itunes:owner>
        <itunes:explicit>No</itunes:explicit>
        <itunes:keywords>
            New Orleans, People, Politics, Entertainment, Art, News, Music, Food
        </itunes:keywords>
        <itunes:image href="https://kneauxla.com/podcasts/kneauxLa_podcast_icon.png"/>
        <rawvoice:rating>TV-G</rawvoice:rating>
        <rawvoice:location>New Orleans, Lousiana</rawvoice:location>
        <rawvoice:frequency>Bi-Monthly</rawvoice:frequency>
        <itunes:category text="News"/>
        <pubDate>Tue, 01 Oct 2020 06:00:00 PDT</pubDate>

		<itunes:category text="News">
		<itunes:category text="News Commentary"></itunes:category>
		</itunes:category>
		  <item>
			<title>KneauxLA: #1 Marc Morial</title>
			<Kperson>Marc Morial</Kperson>
			<Knumber>#1</Knumber>
			<KfeatureImgs>1new_0000s_0000_1.jpg|1new_0000s_0002_2.jpg|1new_0000s_0003_3.jpg|1new_0000s_0004_4.jpg|1new_0000s_0005_5.jpg</KfeatureImgs>
			<Kapple>https://embed.podcasts.apple.com/us/podcast/kneauxla-1-marc-morial/id1536703901?i=1000495489454&amp;itsct=podcast_box_player&amp;itscg=30200</Kapple>
			<Ktags>News</Ktags>
			<link>http://KneauxLA.com/</link>
			<itunes:image href="https://kneauxla.com/podcasts/kneauxLa_podcast_icon.png" />
			<guid>http://kneauxla.com/podcasts/001/Moriel%20Podcast_mixdown3.mp3</guid>
			<description>This episode features Marc Morial, former Mayor of New Orleans and present President and CEO of the National Urban League; where he discusses the important issues facing the African American community.</description>
			<itunes:summary><![CDATA[This episode features Marc Morial, former Mayor of New Orleans and present President and CEO of the National Urban League; where he discusses the important issues facing the African American community.]]></itunes:summary>
			<enclosure url="https://kneauxla.com/podcasts/001/Moriel%20Podcast_mixdown3.mp3" length="40452992" type="audio/mpeg"/>
			<category>Podcasts</category>
 			<itunes:duration>00:20:57</itunes:duration>
			<pubDate>Tue, 01 Oct 2020 06:00:00 PDT</pubDate>
		  </item>
		  <item>
			<title>KneauxLA: #2 Mayor LaToya Cantrell</title>
			<Kperson>Mayor LaToya Cantrell</Kperson>
			<Knumber>#2</Knumber>
			<KfeatureImgs>2_0000_1.jpg|2_0001_2.jpg|2_0002_3.jpg|2_0003_4.jpg|2_0004_5.jpg</KfeatureImgs>
			<Kapple>https://embed.podcasts.apple.com/us/podcast/kneauxla-2-mayor-latoya-cantrell/id1536703901?i=1000500571245&amp;itsct=podcast_box_player&amp;itscg=30200</Kapple>
			<Ktags>News</Ktags>
			<link>http://KneauxLA.com/</link>
			<itunes:image href="https://kneauxla.com/podcasts/kneauxLa_podcast_icon.png" />
			<guid>http://kneauxla.com/podcasts/002/Cantrell_Podcast_mixdown3.mp3</guid>
			<description>This episode features LaToya Cantrell, Mayor of New Orleans; we discuss the December 5th election, the results of the Presidential election and more.</description>
			<itunes:summary><![CDATA[This episode features LaToya Cantrell, Mayor of New Orleans; we discuss the December 5th election, the results of the Presidential election and more.]]></itunes:summary>
			<enclosure url="https://kneauxla.com/podcasts/002/Cantrell_Podcast_mixdown3.mp3" length="30907473" type="audio/mpeg"/>
			<category>Podcasts</category>
 			<itunes:duration>00:21:26</itunes:duration>
			<pubDate>Fri, 27 Nov 2020 06:00:00 PDT</pubDate>
		  </item>
		  <item>
			<title>KneauxLA: #3 Terrance Osborne, Visual Artist</title>
			<Kperson>Terrance Osborne</Kperson>
			<Knumber>#3</Knumber>
			<KfeatureImgs>3_0000_1.jpg|3_0001_2.jpg|3_0002_3.jpg|3_0003_4.jpg|3_0004_5.jpg</KfeatureImgs>
			<Kapple>https://embed.podcasts.apple.com/us/podcast/kneauxla-3-terrance-osborne-visual-artist/id1536703901?i=1000502869354&amp;itsct=podcast_box_player&amp;itscg=30200</Kapple>
			<Ktags>Art</Ktags>
			<link>http://KneauxLA.com/</link>
			<itunes:image href="https://kneauxla.com/podcasts/kneauxLa_podcast_icon.png" />
			<guid>http://kneauxla.com/podcasts/003/Osborne_Podcast_mixdown.mp3</guid>
			<description>This episode features Terrance Osborne, a renowned visual artist from New Orleans whose work has received both critical and commercial acclaim. </description>
			<itunes:summary><![CDATA[This episode features Terrance Osborne, a renowned visual artist from New Orleans whose work has received both critical and commercial acclaim. We discuss what inspires him, what it means to be a New Orleans based artist, and how art can give voice to issues our society faces.]]></itunes:summary>
			<enclosure url="https://kneauxla.com/podcasts/003/Osborne_Podcast_mixdown.mp3" length="36446551" type="audio/mpeg"/>
			<category>Podcasts</category>
 			<itunes:duration>00:25:17</itunes:duration>
			<pubDate>Fri, 18 Dec 2020 06:00:00 PDT</pubDate>
		  </item>
		  <item>
			<title>KneauxLA: #4 Marc Morial (Follow Up Interview)</title>
			<Kperson>Marc Morial (Follow Up)</Kperson>
			<Knumber>#4</Knumber>
			<KfeatureImgs>4new_0001s_0000_1.jpg|4new_0001s_0001_3.jpg|4new_0001s_0002_5.jpg|4new_0001s_0003_2.jpg|4new_0001s_0004_3.jpg</KfeatureImgs>
			<Kapple>https://embed.podcasts.apple.com/us/podcast/kneauxla-4-marc-morial-follow-up-interview/id1536703901?i=1000504982277&amp;itsct=podcast_box_player&amp;itscg=30200</Kapple>
			<Ktags>News</Ktags>
			<link>http://KneauxLA.com/</link>
			<itunes:image href="https://kneauxla.com/podcasts/kneauxLa_podcast_icon.png" />
			<guid>http://kneauxla.com/podcasts/004/Moreal2_mixdown.mp3</guid>
			<description>In this episode we follow up with President and CEO of the National Urban League, Marc Morial - discussing what we can expect from the new administration, criminal justice reform, family, leadership and much more. </description>
			<itunes:summary><![CDATA[In this episode we follow up with President and CEO of the National Urban League, Marc Morial - discussing what we can expect from the new administration, criminal justice reform, family, leadership and much more.]]></itunes:summary>
			<enclosure url="https://kneauxla.com/podcasts/004/Moreal2_mixdown.mp3" length="28331741" type="audio/mpeg"/>
			<category>Podcasts</category>
 			<itunes:duration>00:19:40</itunes:duration>
			<pubDate>Sun, 10 Jan 2021 06:00:00 PDT</pubDate>
		  </item>
		  <item>
			<title>KneauxLA: #5 LaRicha and John Alfred, Parents of Jamere Alfred, a 14-year-old who was murdered on Christmas Day in New Orleans</title>
			<Kperson>LaRicha and John Alfred</Kperson>
			<Knumber>#5</Knumber>
			<KfeatureImgs>5_0000_1.jpg|5_0001_2.jpg|5_0002_3.jpg|5_0003_4.jpg|5_0004_5.jpg</KfeatureImgs>
			<Kapple>https://embed.podcasts.apple.com/us/podcast/kneauxla-5-laricha-and-john-alfred-parents-of/id1536703901?i=1000506804191&amp;itsct=podcast_box_player&amp;itscg=30200</Kapple>
			<Ktags>News</Ktags>
			<link>http://KneauxLA.com/</link>
			<itunes:image href="https://kneauxla.com/podcasts/kneauxLa_podcast_icon.png" />
			<guid>http://kneauxla.com/podcasts/005/Jameer_mixdown2.mp3</guid>
			<description>In this episode we talk with the parents of Jamere Alfred, a 14-year-old boy who had a bright future - who enjoyed playing in the band and on the football team and who tragically, on Christmas Day was shot and killed on his way to a local Walgreens.  LaRicha and John share memories of their son, thoughts on the senseless violence in New Orleans, and what they intend to do about it.</description>
			<itunes:summary><![CDATA[In this episode we talk with the parents of Jamere Alfred, a 14-year-old boy who had a bright future - who enjoyed playing in the band and on the football team and who tragically, on Christmas Day was shot and killed on his way to a local Walgreens.  LaRicha and John share memories of their son, thoughts on the senseless violence in New Orleans, and what they intend to do about it.]]></itunes:summary>
			<enclosure url="https://kneauxla.com/podcasts/005/Jameer_mixdown2.mp3" length="26714903" type="audio/mpeg"/>
			<category>Podcasts</category>
 			<itunes:duration>00:18:34</itunes:duration>
			<pubDate>Wed, 27 Jan 2021 06:00:00 PDT</pubDate>
		  </item>
		  <item>
			<title>KneauxLA: #6 Big Chief Shaka Zulu</title>
			<Kperson>Big Chief Shaka Zulu</Kperson>
			<Knumber>#6</Knumber>
			<KfeatureImgs>6_0004_1.jpg|6_0005_2.jpg|6_0009_5.jpg|6_0008_4.jpg|6_0007_3.jpg</KfeatureImgs>
			<Kapple>https://embed.podcasts.apple.com/us/podcast/kneauxla-6-big-chief-shaka-zulu/id1536703901?i=1000508852576&amp;itsct=podcast_box_player&amp;itscg=30200</Kapple>
			<Ktags>Art</Ktags>
			<link>http://KneauxLA.com/</link>
			<itunes:image href="https://kneauxla.com/podcasts/kneauxLa_podcast_icon.png" />
			<guid>http://kneauxla.com/podcasts/006/Shaka_Zulu_mixdown.mp3</guid>
			<description>This episode features Big Chief Shaka Zulu, a renown culture bearer/keeper from New Orleans. We discuss Voices of Congo Square, his stage play that tells the authentic story of Black New Orleans, as well as the history, importance and future of the Black Carnival traditions of the region. Throughout his life, Shaka has been involved in many aspects of the creative cultural community of New Orleans acting as an ambassador for the city across the globe.</description>
			<itunes:summary><![CDATA[This episode features Big Chief Shaka Zulu, a renown culture bearer/keeper from New Orleans. We discuss Voices of Congo Square, his stage play that tells the authentic story of Black New Orleans, as well as the history, importance and future of the Black Carnival traditions of the region. Throughout his life, Shaka has been involved in many aspects of the creative cultural community of New Orleans acting as an ambassador for the city across the globe.]]></itunes:summary>
			<enclosure url="https://kneauxla.com/podcasts/006/Shaka_Zulu_mixdown.mp3" length="43426527" type="audio/mpeg"/>
			<category>Podcasts</category>
 			<itunes:duration>00:30:11</itunes:duration>
			<pubDate>Fri, 13 Feb 2021 06:00:00 PDT</pubDate>
		  </item>
		  <item>
			<title>KneauxLA: #7 Leona Tate</title>
			<Kperson>Leona Tate</Kperson>
			<Knumber>#7</Knumber>
			<KfeatureImgs>7_0002_3.jpg|7_0000_1.jpg|7_0001_2.jpg|7_0003_4.jpg|7_0004_5.jpg</KfeatureImgs>
			<Kapple>https://embed.podcasts.apple.com/us/podcast/kneauxla-7-leona-tate/id1536703901?i=1000521680261&amp;itsct=podcast_box_player&amp;itscg=30200</Kapple>
			<Ktags>News</Ktags>
			<link>http://KneauxLA.com/</link>
			<itunes:image href="https://kneauxla.com/podcasts/kneauxLa_podcast_icon.png" />
			<guid>http://kneauxla.com/podcasts/007/LeonaTate-March_mixdown3.mp3</guid>
			<description>In 1960, six years after Brown v. Board of Education was struck down, three 6 years old black girls were escorted by Federal Marshals through a crowd of protesters to attend McDonogh #19.  Known as the McDonogh Three, Leona Tate, Gail Etienne and Tessie Prevost became among the first to desegregate formerly all white Elementary Public Schools in New Orleans.  In this episode we speak with  Leona Tate, who has acquired  McDonogh 19, the school she and her fellow pioneers help desegregate. She has worked for many years to reopen the building which was damaged by Hurricane Katrina and was shuttered for many years. We talk about her journey, how she’s continuing to fight for justice, and how she is working to make the school a monument to the vital history it represents.</description>
			<itunes:summary><![CDATA[In 1960, six years after Brown v. Board of Education was struck down, three 6 years old black girls were escorted by Federal Marshals through a crowd of protesters to attend McDonogh #19.  Known as the McDonogh Three, Leona Tate, Gail Etienne and Tessie Prevost became among the first to desegregate formerly all white Elementary Public Schools in New Orleans.  In this episode we speak with  Leona Tate, who has acquired  McDonogh 19, the school she and her fellow pioneers help desegregate. She has worked for many years to reopen the building which was damaged by Hurricane Katrina and was shuttered for many years. We talk about her journey, how she’s continuing to fight for justice, and how she is working to make the school a monument to the vital history it represents.]]></itunes:summary>
			<enclosure url="https://kneauxla.com/podcasts/007/LeonaTate-March_mixdown3.mp3" length="29386071" type="audio/mpeg"/>
			<category>Podcasts</category>
 			<itunes:duration>00:20:24</itunes:duration>
			<pubDate>Mon, 5 Apr 2021 06:00:00 PDT</pubDate>
		  </item>
		  <item>
			<title>KneauxLA: #8 Tyra G. Morrison, Actress, Youtube Star </title>
			<Kperson>Tyra G. Morrison</Kperson>
			<Knumber>#8</Knumber>
			<KfeatureImgs>8_0000_1.jpg|8_0001_2.jpg|8_0002_3.jpg|8_0005_4.jpg|8_0006_5.jpg</KfeatureImgs>
			<Kapple>https://embed.podcasts.apple.com/us/podcast/kneauxla-8-tyra-g-morrison-actress-youtube-star/id1536703901?i=1000515803851&amp;itsct=podcast_box_player&amp;itscg=30200</Kapple>
			<Ktags>Art Media</Ktags>
			<link>http://KneauxLA.com/</link>
			<itunes:image href="https://kneauxla.com/podcasts/kneauxLa_podcast_icon.png" />
			<guid>http://kneauxla.com/podcasts/008/Tyra_mixdown2.mp3</guid>
			<description>In this episode, Tyra G. Morrison “Tyra The Creative” - an actress who may be best known for the award winning Netflix show Family Reunion.  Originally from a small town outside of Baton Rouge, Louisiana - she is an Actress, Content Creator and a Plant Based Pastry Chef on the side.  We talk about her life and how a bit of “Black Girl Magic” is continuing to help her become a rising star on film/TV and in the world of Social Media.</description>
			<itunes:summary><![CDATA[Tyra G. Morrison “Tyra The Creative” is an actress who may be best known for the award winning Netflix show Family Reunion.  Originally from a small town outside of Baton Rouge, Louisiana - she is an Actress, Content Creator and a Plant Based Pastry Chef on the side.  We talk about her life and how a bit of “Black Girl Magic” is continuing to help her become a rising star on film/TV and in the world of Social Media.]]></itunes:summary>
			<enclosure url="https://kneauxla.com/podcasts/008/Tyra_mixdown2.mp3" length="40213279" type="audio/mpeg"/> 
			<category>Podcasts</category>
 			<itunes:duration>00:27:57</itunes:duration>
			<pubDate>Mon, 9 May 2021 06:00:00 PDT</pubDate> 
		  </item>
		  <item>
			<title>KneauxLA: #9 Angel Chung Cutno, Passionate, and Innovative Community Organizer, Artist, Social Chameleon </title>
			<Kperson>Angel Chung Cutno</Kperson>
			<Knumber>#9</Knumber>
			<KfeatureImgs>9_0000_1.jpg|9_0002_2.jpg|9_0003_3.jpg|9_0004_4.jpg|9_0005_5.jpg</KfeatureImgs>
			<Kapple>https://embed.podcasts.apple.com/us/podcast/kneauxla-9-angel-chung-cutno-passionate-and/id1536703901?i=1000529437219&amp;itsct=podcast_box_player&amp;itscg=30200</Kapple>
			<Ktags>Art</Ktags>
			<link>http://KneauxLA.com/</link>
			<itunes:image href="https://kneauxla.com/podcasts/kneauxLa_podcast_icon.png" />
			<guid>http://kneauxla.com/podcasts/009/Angel_mixdown2.mp3</guid>
			<description>In this edition of the Kneauxla podcast we talk with Angel Chung Cutno - a  renown community organizer, artist, social chameleon and youth mentor.  We discuss her passion for improving literacy in New Orleans, the state of charter schools, and her unique perspective on building bridges between cultures.</description>
			<itunes:summary><![CDATA[In this edition of the Kneauxla podcast we talk with Angel Chung Cutno - a  renown community organizer, artist, social chameleon and youth mentor.  We discuss her passion for improving literacy in New Orleans, the state of charter schools, and her unique perspective on building bridges between cultures.]]></itunes:summary>
			<enclosure url="https://kneauxla.com/podcasts/009/Angel_mixdown2.mp3" length="42898565" type="audio/mpeg"/> 
			<category>Podcasts</category>
 			<itunes:duration>00:29:48</itunes:duration>
			<pubDate>Mon, 12 July 2021 06:00:00 PDT</pubDate> 
		  </item>
		  <item>
			<title>KneauxLA: #10 Delfeayo Marsalis, Acclaimed Trombonist, Composer, and Producer </title>
			<Kperson>Delfeayo Marsalis</Kperson>
			<Knumber>#10</Knumber>
			<KfeatureImgs>10_0000_1.jpg|10_0001_2.jpg|10_0002_3.jpg|10_0003_4.jpg|10_0004_5.jpg</KfeatureImgs>
			<Kapple>https://embed.podcasts.apple.com/us/podcast/kneauxla-10-delfeayo-marsalis-acclaimed-trombonist/id1536703901?i=1000533310218&amp;itsct=podcast_box_player&amp;itscg=30200</Kapple>
			<Ktags>Art Media</Ktags>
			<link>http://KneauxLA.com/</link>
			<itunes:image href="https://kneauxla.com/podcasts/kneauxLa_podcast_icon.png" />
			<guid>http://kneauxla.com/podcasts/010/marsalis_mixdown.mp3</guid>
			<description>This episode we vibe with acclaimed trombonist, producer and all around cool brotha Delfeayo Marsalis. We discuss his upbringing being raised in one of New Orleans most famous musical families, his latest production the Jazz at Congo Square Festival, as well as how he strives to follow in his famous fathers footsteps and keep it real.</description>
			<itunes:summary><![CDATA[This episode we vibe with acclaimed trombonist, producer and all around cool brotha Delfeayo Marsalis. We discuss his upbringing being raised in one of New Orleans most famous musical families, his latest production the Jazz at Congo Square Festival, as well as how he strives to follow in his famous fathers footsteps and keep it real.]]></itunes:summary>
			<enclosure url="https://kneauxla.com/podcasts/010/marsalis_mixdown.mp3" length="27820038" type="audio/mpeg"/> 
			<category>Podcasts</category>
 			<itunes:duration>00:19:20</itunes:duration>
			<pubDate>Thu, 26 August 2021 06:00:00 PDT</pubDate> 
		  </item>
		  <item>
			<title>KneauxLA: #11 Exactly 16 years after Katrina, Hurricane Ida strikes New Orleans.  Writer, Radio Host, and Actress, Samantha Beaulieu shares her experience living through both disasters. </title>
			<Kperson>Samantha Beaulieu</Kperson>
			<Knumber>#11</Knumber>
			<KfeatureImgs>11-_0000_1.jpg|11-_0001_2.jpg|11-_0002_3.jpg|11-_0005_4.jpg|11-_0006_5.jpg</KfeatureImgs>
			<Kapple>https://embed.podcasts.apple.com/us/podcast/kneauxla-11-exactly-16-years-after-katrina-hurricane/id1536703901?i=1000537264390&amp;itsct=podcast_box_player&amp;itscg=30200</Kapple>
			<Ktags>News Media</Ktags>
			<link>http://KneauxLA.com/</link>
			<itunes:image href="https://kneauxla.com/podcasts/kneauxLa_podcast_icon.png" />
			<guid>https://kneauxla.com/podcasts/011/IDA_mixdown.mp3</guid>
			<description>Exactly 16 years to the day after Katrina hit, the people of New Orleans met hurricane Ida.  On this episode of the KneauxLA Podcast we get a first hand account from someone who experienced both. Samantha Beaulieu, a writer, radio host and actress who has stared in shows like HBO's "Treme"; "American Horror Story"; and "NCIS: New Orleans, takes us through what you actually do when you realize a hurricane is about to hit, the little silver linings you discover in a disaster, and what happens to all of that food left in your refrigerator when there’s no power for weeks on end.</description>
			<itunes:summary><![CDATA[Exactly 16 years to the day after Katrina hit, the people of New Orleans met hurricane Ida.  On this episode of the KneauxLA Podcast we get a first hand account from someone who experienced both. Samantha Beaulieu, a writer, radio host and actress who has stared in shows like HBO's "Treme"; "American Horror Story"; and "NCIS: New Orleans, takes us through what you actually do when you realize a hurricane is about to hit, the little silver linings you discover in a disaster, and what happens to all of that food left in your refrigerator when there’s no power for weeks on end.]]></itunes:summary>
			<enclosure url="https://kneauxla.com/podcasts/011/IDA_mixdown.mp3" length="23979488" type="audio/mpeg"/> 
			<category>Podcasts</category>
 			<itunes:duration>00:16:40</itunes:duration>
			<pubDate>Mon, 27 September 2021 06:00:00 PDT</pubDate> 
		  </item>
		  <item>
			<title>KneauxLA: #12 Lucky Johnson, Activist, Actor, Comedian, Philanthropist </title>
			<Kperson>Lucky Johnson</Kperson>
			<Knumber>#12</Knumber>
			<KfeatureImgs>l12-A.jpg|l12-B.jpg|l12-C.jpg|l12-E.jpg|l12-H.jpg</KfeatureImgs>
			<Kapple>https://embed.podcasts.apple.com/us/podcast/kneauxla-12-lucky-johnson-activist-actor-comedian-philanthropist/id1536703901?i=1000540345299&amp;itsct=podcast_box_player&amp;itscg=30200</Kapple>
			<Ktags>News Media</Ktags>
			<link>http://KneauxLA.com/</link>
			<itunes:image href="https://kneauxla.com/podcasts/kneauxLa_podcast_icon.png" />
			<guid>https://kneauxla.com/podcasts/012/Lucky_mixdown.mp3</guid>
			<description>In this episode we build with activist, actor, comedian, philanthropist and New Orleans native Lucky Johnson, who’s worked along side stars like The Rock, Denzel Washington and even his first cousin Tyler Perry, among others. We talk about his continuing activism in the wake of Hurricane IDA and what he’s doing to try to help the city he was born and bread in.</description>
			<itunes:summary><![CDATA[In this episode we build with activist, actor, comedian, philanthropist and New Orleans native Lucky Johnson, who’s worked along side stars like The Rock, Denzel Washington and even his first cousin Tyler Perry, among others. We talk about his continuing activism in the wake of Hurricane IDA and what he’s doing to try to help the city he was born and bread in.]]></itunes:summary>
			<enclosure url="https://kneauxla.com/podcasts/012/Lucky_mixdown.mp3" length="32400174" type="audio/mpeg"/> 
			<category>Podcasts</category>
 			<itunes:duration>00:22:31</itunes:duration>
			<pubDate>Sat, 30 October 2021 06:00:00 PDT</pubDate> 
		  </item>
		  <item>
			<title>KneauxLA: #13 Bo Dollis Jr., Wild Magnolias Mardi Gras Indian tribe </title>
			<Kperson>Big Chief Bo Dollis Jr.</Kperson>
			<Knumber>#13</Knumber>
			<KfeatureImgs>13_0007_3.jpg|13_0005_1.jpg|13_0006_2.jpg|13_0008_4.jpg|13_0009_5.jpg</KfeatureImgs>
			<Kapple>https://embed.podcasts.apple.com/us/podcast/kneauxla-13-bo-dollis-jr-wild-magnolias-mardi-gras/id1536703901?i=1000543916912&amp;itsct=podcast_box_player&amp;itscg=30200</Kapple>
			<Ktags>News Art</Ktags>
			<link>http://KneauxLA.com/</link>
			<itunes:image href="https://kneauxla.com/podcasts/kneauxLa_podcast_icon.png" />
			<guid>https://kneauxla.com/podcasts/013/BoDallas_mixdown.mp3</guid>
			<description>On this episode we get down with singer and musician Big Chief Bo Dollis Jr., son of famed Mardi Gras Indian Bo Dollis of the Wild Magnolias Mardi Gras Indian tribe.  We talk about his new released "My Name is Bo", the history of Madi Gras Indians, and the challenge of filling the shoes (and suit) of his famous father. </description>
			<itunes:summary><![CDATA[On this episode we get down with singer and musician Big Chief Bo Dollis Jr., son of famed Mardi Gras Indian Bo Dollis of the Wild Magnolias Mardi Gras Indian tribe.  We talk about his new released "My Name is Bo", the history of Madi Gras Indians, and the challenge of filling the shoes (and suit) of his famous father. ]]></itunes:summary>
			<enclosure url="https://kneauxla.com/podcasts/013/BoDallas_mixdown.mp3" length="18047440" type="audio/mpeg"/> 
			<category>Podcasts</category>
 			<itunes:duration>00:12:32</itunes:duration>
			<pubDate>Thu, 2 December 2021 06:00:00 PDT</pubDate> 
		  </item>	
		  <item>
			<title>KneauxLA: #14 Lesli Harris, New Orleans City Council - District B </title>
			<Kperson>Councilwoman Lesli Harris</Kperson>
			<Knumber>#14</Knumber>
			<KfeatureImgs>13-_0001_c.png|13-_0000_0.png|13-_0002_a.png|13-_0003_b.png|13-_0004_d.png</KfeatureImgs>
			<Kapple>https://embed.podcasts.apple.com/us/podcast/kneauxla-14-lesli-harris-new-orleans-city-council-district/id1536703901?i=1000548305895&amp;itsct=podcast_box_player&amp;itscg=30200</Kapple>
			<Ktags>News Art</Ktags>
			<link>http://KneauxLA.com/</link>
			<itunes:image href="https://kneauxla.com/podcasts/kneauxLa_podcast_icon.png" />
			<guid>https://kneauxla.com/podcasts/014/Lesli_Harris_mixdown2.mp3</guid>
			<description>On this episode we chat with newly elected Councilwoman Lesli Harris who represents one of the most diverse districts in the city of New Orleans.  Two of the city’s most famous streets - St. Charles Avenue and Magazine Street - is in District B. We learn about her top priorities for the city, and how she plans to help tackle issues like crime, blight, and keeping the best and brightest minds at home in her adopted city.</description>
			<itunes:summary><![CDATA[On this episode we chat with newly elected Councilwoman Lesli Harris who represents one of the most diverse districts in the city of New Orleans.  Two of the city’s most famous streets - St. Charles Avenue and Magazine Street - is in District B. We learn about her top priorities for the city, and how she plans to help tackle issues like crime, blight, and keeping the best and brightest minds at home in her adopted city.]]></itunes:summary>
			<enclosure url="https://kneauxla.com/podcasts/014/Lesli_Harris_mixdown2.mp3" length="30845813" type="audio/mpeg"/> 
			<category>Podcasts</category>
 			<itunes:duration>00:21:26</itunes:duration>
			<pubDate>Tue, 18 January 2022 06:00:00 PDT</pubDate> 
		  </item>			  	  
    </channel>
</rss>

`;

var xml = new XMLParser().parseFromString(xml_data);
const jsonDataFromXml = new XMLParser().parseFromString("");
//console.log(xml.children[0].name);
let items   = [];
let items2  = {};
let items3  = [];
let misc    = {};
let pages   = []; const itemPageName = "episode";
	pages.push({uri1:"Home", uri2:"", uri3:""});
	//pages.push({uri1:"About", uri2:"", uri3:""});
xml.children.map( child => {
	if(child.name === "channel"){
		//console.log(child.name);
		child.children.map( (item, k) => {
			if(item.name === "item"){
				//console.log("****---***" + item.children[0].name);
				//console.log(item.name);
				items.push(item);

				const thisItem = {};
				item.children.map(i => {
					//console.log( i.value );
					//console.log("&&&&&&&");
					thisItem[ i.name ] = i.value;
					if( i.name === 'KfeatureImgs' ){

						const pics = i.value.split("|");
						if( pics.length > 1 ){
							thisItem[ 'KfeatureImgArray' ] 		= pics;
							thisItem[ 'KrandomFeatureImg' ] 	= pics[ random(0, (pics.length - 1) ) ];
						}
						
					}
				});

				items3.push(thisItem);
				//items2[ item.children[0].value ] = thisItem;
				//items2[ item.children[0].name ] = item.children[0].value;
				pages.push({uri1:itemPageName, uri2:thisItem.Knumber, uri3:"", key: (items.length -1) });
			}else{
				misc[item.name] = item;
			}
		})
	}
})

const menu = [
	{displayTitle: "Home", link: "#"},
	{displayTitle: "About", link: "#"},
	{displayTitle: "Episodes", link: "#"},
	{displayTitle: "News", link: "#"},
	{displayTitle: "Contact", link: "#"},
	{displayTitle: "Support", link: "#", flag: "special"},
];

const menuSocial = [
	{displayTitle: "RSS", faIconClassNames: "fa  fa-rss", link: "#"},
	{displayTitle: "Facebook", faIconClassNames: "fa fa-facebook-official", link: "#"},
	{displayTitle: "Twitter", faIconClassNames: "fa fa-twitter", link: "#"},
	{displayTitle: "Instagram", faIconClassNames: "fa fa-instagram", link: "#"},
	{displayTitle: "Spotify", faIconClassNames: "fa fa-spotify", link: "#"},
];

const logo = {
	src: "/kneauxLa_05b.png",
	src2:"/kneauxLa_05.png",
	link:"#", 
	alt:"logo"
};

const bgImgs = {
	1  : "kneauxla9.jpg",
	2  : "kneauxla10.jpg",
	2  : "kneauxla8.jpg",
	3  : "kneauxla3.jpg",
	4  : "kneauxla4.jpg",
	5  : "kneauxla5.jpg",
	6  : "kneauxla6.jpg",
	7  : "kneauxla7.jpg",
};

const bgImgsXX = {
	1  : "kneauxla7.jpg",
	2  : "kneauxla9.jpg",
	3  : "kneauxla3.jpg",
	4  : "kneauxla4.jpg",
	5  : "kneauxla6.jpg",
};
const collage = []
		collage.push("sm_0001_IMG_2756.jpg");
		collage.push("sm_0002_2.jpg");
		collage.push("sm_0003_3.jpg");
		collage.push("sm_0004_4.jpg");
		collage.push("sm_0005_5.jpg");
		collage.push("sm_0006_6.jpg");
		collage.push("sm_0007_7.jpg");
		collage.push("sm_0008_8.jpg");
		collage.push("sm_0009_9.jpg");
		collage.push("sm_0010_10.jpg");
		collage.push("sm_0011_11.jpg");
		collage.push("sm_0012_12.jpg");

		collage.push("sm2_0018_IMG_1775.jpg");
		collage.push("sm2_0000_IMG_1485 copy.jpg");
		collage.push("sm2_0001_IMG_3086.jpg");
		collage.push("sm2_0002_IMG_3114.jpg");
		collage.push("sm2_0003_IMG_3113.jpg");
		collage.push("sm2_0004_IMG_3118.jpg");
		collage.push("sm2_0005_IMG_3121 copy.jpg");
		collage.push("sm2_0006_IMG_3117.jpg");
		collage.push("sm2_0007_IMG_3132.jpg");
		collage.push("sm2_0008_IMG_0670_3064759576_2430.jpg");
		collage.push("sm2_0009_IMG_0727_2686948690_2480.jpg");
		collage.push("sm2_0010_IMG_0739_1400358435_2485.jpg");
		collage.push("sm2_0011_IMG_0839_38644997_2530.jpg");
		collage.push("sm2_0012_IMG_1016_1871053773_2598.jpg");
		collage.push("sm2_0013_IMG_1023_3283497216_2601.jpg");
		collage.push("sm2_0014_IMG_1027_1956008978_2604.jpg");
		collage.push("sm2_0015_IMG_1565_1159206075_2768.jpg");
		collage.push("sm2_0016_IMG_1174.jpg");
		collage.push("sm2_0017_IMG_1521.jpg");		

/*const collage = []
		collage.push("coll_0010_12.jpg");
		collage.push("coll_0000_2.jpg");
		collage.push("coll_0001_3.jpg");
		collage.push("coll_0002_4.jpg");
		collage.push("coll_0003_5.jpg");
		collage.push("coll_0004_6.jpg");
		collage.push("coll_0005_7.jpg");
		collage.push("coll_0006_8.jpg");
		collage.push("coll_0007_9.jpg");
		collage.push("coll_0008_10.jpg");
		collage.push("coll_0009_11.jpg");
		collage.push("coll_0011_13.jpg");*/
const collageA = []
		collageA.push("coll_0010_12.jpg");
		collageA.push("coll_0000_2.jpg");
		collageA.push("coll_0001_3.jpg");
		collageA.push("coll_0002_4.jpg");
		collageA.push("coll_0003_5.jpg");
		collageA.push("coll_0004_6.jpg");
const collageB = []
		collageB.push("coll_0005_7.jpg");
		collageB.push("coll_0006_8.jpg");
		collageB.push("coll_0007_9.jpg");
		collageB.push("coll_0008_10.jpg");
		collageB.push("coll_0009_11.jpg");
		collageB.push("coll_0011_13.jpg");
// const collage = {

// 	1  : "coll_0010_12.jpg",
// 	2  : "coll_0000_2.jpg",
// 	3  : "coll_0001_3.jpg",
// 	4  : "coll_0002_4.jpg",
// 	5  : "coll_0003_5.jpg",
// 	6  : "coll_0004_6.jpg",
// 	7  : "coll_0005_7.jpg",
// 	8  : "coll_0006_8.jpg",
// 	9  : "coll_0007_9.jpg",
// 	10  : "coll_0008_10.jpg",
// 	11  : "coll_0009_11.jpg",

// }

const getVarNames = {
	'page'  	: "&p=",
	'episode'  	: "&e=",
	'guest'  	: "&g=",
};

const socialMedia = {
	'patreon'  	: "http://www.patreon.com/kneauxla",
};

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

const properties = {
  sitePages: pages,
  podItemPageName: itemPageName,
  siteLogo: logo,
  mainMenu: menu,
  menuSocialIcons: menuSocial,
  items: items3,
  miscInfo: misc,
  backgrounImgs: bgImgs,
  collageImgs: collage,
  collageImgsA: collageA,
  collageImgsB: collageB,
  randomFun: random,
  uri: window.location.href.split('/'),
  social:socialMedia,
  getVar:getVarNames,
  getParams:params,
  basePage:"/index.html?",
  episodesPage:"All-Episodes",
};

//console.log(misc);
//console.log(items, "/////////////////////");
console.log(properties, "/////////////////////");
//console.log(items2);
//console.log(items3);
//console.log(items3[11].title);
//console.log("/////////////////////");
console.log(urlSearchParams, "/////////////////////");
console.log(params, "/////////////////////");

export default properties