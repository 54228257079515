const KDateBlocks = ({props}) => {
	const date = props;
	return (
		(new Date( date ).toLocaleString([], {year: 'numeric', month: 'short', day: 'numeric', hour12: 'false'})) + " " + 
		""
	);
}

const PodPlayer = ({pod, props, theme}) => {
	const p = pod;
	
	return (
		<>
			<a href={ props.basePage + props.getVar.page + props.podItemPageName.toLowerCase() + props.getVar.episode + p.Knumber.replace('#', '') + props.getVar.guest + encodeURI(p.Kperson).replaceAll("%20", "+")  }    className="primary-btn">Episode {p.Knumber}: {p.Kperson}</a>
		</>
	);
	/*return (
		<>
			<a href={"#/" + props.podItemPageName.toLowerCase() + "/" + p.Knumber.replace('#', '') + "/" + encodeURI(p.Kperson).replaceAll("%20", "+") } className="primary-btn">Episode {p.Knumber}: {p.Kperson}</a>
		</>
	);
	return (
		<>
			<iframe src={p.Kapple} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow="autoplay *; encrypted-media *;"  style={{width: "100%", maxWidth: "860px",  overflow: "hidden",  borderRadius: "10px",  background: "transparent none repeat scroll 0% 0%"}} height="175px" frameborder="0"></iframe>
		</>
	);*/
}
const Pods = ({props}) => {
	const pods = props.items;
	const podKeysReversed = Object.keys(props.items).reverse();
	//podKeysReversed.shift();
	//console.log( podKeysReversed );
	const bgImag 		= props.backgrounImgs[props.randomFun(1, (Object.keys(props.backgrounImgs).length + 1))];

	return (	
		<>
			<section className="podcast spad">
				<div className="container">
					<div className="podcast__top">
						<div className="row">
							<div className="col-lg-5 col-md-5">
								<h2>Episodes by Mood:</h2>
							</div>
							<div className="col-lg-7 col-md-7">
								<ul className="filter__controls">
									<li className="active Ktags" data-filter="*">All of {process.env.REACT_APP_SHORTNAME}</li>
									<li className="Ktags" data-filter=".art">Art/Music</li>
{/*									<li className="Ktags" data-filter=".culture">Culture/Traditions</li>
*/}									<li className="Ktags" data-filter=".media">Media/Ent.</li>
									<li className="Ktags" data-filter=".news">News/Politics/Society</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="row podcast-filter" id="MixItUp16C821">
						{podKeysReversed.map( (p, k) => 

							//<div key={"Kpod" + k} className="col-lg-12 mix entrepreneurship politics tech">
							<div key={"Kpod" + k} className={"col-lg-12 mix " + pods[p]['Ktags'].toLowerCase() }>
								<div className="podcast__item">
									<div className="podcast__item__pic">
										
										{/* <a href={"/index.html/#/" + props.podItemPageName.toLowerCase() + "/" + pods[p].Knumber.replace('#', '') + "/" + encodeURI(pods[p].Kperson).replaceAll("%20", "+")  } className=""   >*/}
										<a href={ props.basePage + props.getVar.page + props.podItemPageName.toLowerCase() + props.getVar.episode + pods[p].Knumber.replace('#', '') + props.getVar.guest + encodeURI(pods[p].Kperson).replaceAll("%20", "+")  } className=""   >
										<img 
											style={{width: "310px"}} 
											key={"Kpod-img" + k}
											title={"Play Ep." + pods[p]['Knumber'] + ": " +  pods[p]['Kperson']} 
											alt={"Goto: " + pods[p]['Kperson']} 
											src={(pods[p]['KrandomFeatureImg'] !== undefined ) 
												? ( "/featureImgs/" + pods[p]['Knumber'].replace('#', '') + "/"  +pods[p]['KrandomFeatureImg']) 
												: ('/imgs/' + props.backgrounImgs[props.randomFun(1, (Object.keys(props.backgrounImgs).length + 1))] )}
										/>
										</a>
								</div>
									<div className="podcast__item__text">
										<ul>
											<li><span className="arrow_right"></span> Episode {pods[p]['Knumber']} </li>
											<li><span className="icon_calendar"></span> <KDateBlocks props={pods[p]['pubDate']} /></li>
{/*
											<li><span className="icon_profile"></span> by Bougage & Rue</li>
*/}
											<li><span className="icon_tags_alt"></span> {pods[p]['Ktags'].split(' ').sort().join(', ')}</li>
										</ul>
										<h4>{pods[p]['title']}</h4>
										<p>{pods[p]['itunes:summary']}</p>

										<div className="track__option">

{/*											<div className="jp-jplayer jplayer" data-ancestor=".jp_container-1" data-url="music-files/3.mp3" id="jp_jplayer_1" style={{width: "0px", height: "0px"}}>
												<img id="jp_poster_1" style={{width: "0px", height: "0px", display: "none"}} /><audio id="jp_audio_1" preload="metadata" src="https://preview.colorlib.com/theme/megapod/music-files/3.mp3"></audio>
											</div>
*/}
											<PodPlayer pod={pods[p]} props={props} theme={"dark"} />

										</div>
									
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</section>
		</>
	);
}

const PodBlocks = ({props}) => {

	const pods = props.items;
	const podKeysReversed = Object.keys(props.items).reverse();

	return (	
		<>
			<section className="episodes spad">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-title">
								<h2>Latest Episodes</h2>
							</div>
						</div>
					</div>
					<div className="row">
						{podKeysReversed.map( (p, k) => 
							<div key={"KpodBlock" + k} className="col-lg-4 col-md-6">
								<div className="episodes__item set-bg" 
										data-setbg={
											(pods[p]['KrandomFeatureImg'] !== undefined ) 
											? ( "/featureImgs/" + pods[p]['Knumber'].replace('#', '') + "/"  +pods[p]['KfeatureImgArray'][0]) 
											: ('/imgs/' + props.backgrounImgs[props.randomFun(1, (Object.keys(props.backgrounImgs).length + 1))] )
										}
										style={{backgroundImage: "url( " + ('/imgs/' + props.backgrounImgs[props.randomFun(1, (Object.keys(props.backgrounImgs).length + 1))] ) + ")"}}>
									<div className="tags">
										<span className="icon_tags_alt"></span> {pods[p]['Ktags'].split(' ').sort().join(', ')}
									</div>
									<div className="time">
										<span className="icon_clock_alt"></span> {pods[p]['itunes:duration']} mins
									</div>
									{/*<a className="play-btn" href={"#/" + props.podItemPageName.toLowerCase() + "/" + pods[p]['Knumber'].replace('#', '') + "/" + encodeURI(pods[p]['Kperson']).replaceAll("%20", "+")}><img alt="" data-pagespeed-url-hash="1370137998"  src="/imgs/play3.png" /></a>*/}
									<a className="play-btn" href={props.basePage + props.getVar.page + props.podItemPageName.toLowerCase() + props.getVar.episode + pods[p]['Knumber'].replace('#', '') + props.getVar.guest + encodeURI(pods[p]['Kperson']).replaceAll("%20", "+")}><img alt="" data-pagespeed-url-hash="1370137998"  src="/imgs/play3.png" /></a>
									<div className="episodes__text">
										<h4><sub>Episode {pods[p]['Knumber']}:</sub> <br />{pods[p]['Kperson']}</h4>
										<p><span className="icon_calendar"></span> <KDateBlocks props={pods[p]['pubDate']} /></p>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				{ props.getParams.p === undefined || (props.getParams.p !== undefined && props.getParams.p.toLowerCase() !== props.episodesPage.toLowerCase() ) ?
				<p style={{textAlign:"center", margin:"0", padding:"0"}}>
					<a className="primary-btn Kmore-episodes" href={props.basePage + props.getVar.page + props.episodesPage.toLowerCase()} ><i class="fa fa-search"></i> Find More Episodes</a>
				</p>
				 : "" }

			</section>

		</>
	);
}

const Support = ({props}) => {
	const bgImag 		= props.backgrounImgs[props.randomFun(1, (Object.keys(props.backgrounImgs).length + 1))];

	return (
	<>
		<section className="callto spad set-bg" data-setbg={"/imgs/support2.jpg" /*+ bgImag*/} style={{backgroundImage: `url("/imgs/support2.jpg/")`, backgroundPosition: "bottom"}} >
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="callto__text">
							<h2>Support {process.env.REACT_APP_SHORTNAME}</h2>
							<p>Enjoying {process.env.REACT_APP_PODNAME}? 
							<br />Help us continue our work to highlight and preserve New Orleans culture. 
							<br />Make a donation below!</p><a target="_blank" className="primary-btn" href={props.social.patreon}>Support {process.env.REACT_APP_SHORTNAME}</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>
	);
}

const More = ({props}) => {
	const bgImag 		= props.backgrounImgs[props.randomFun(1, (Object.keys(props.backgrounImgs).length + 1))];

	return (
	<>
		<section className="callto spad set-bg" data-setbg={"/imgs/support2.jpg" /*+ bgImag*/} style={{backgroundImage: `url("/imgs/support2.jpg/")`, backgroundPosition: "bottom"}} >
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="callto__text">
							<h2>More Episodes</h2>
							<p>
								Listen to more episodes of {process.env.REACT_APP_PODNAME}
							</p>
							<a className="primary-btn" href={props.basePage + props.getVar.page + props.episodesPage.toLowerCase()}>More Episodes</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	</>
	);
}

const About = ({props}) => {
	const bgImag 		= props.backgrounImgs[props.randomFun(1, (Object.keys(props.backgrounImgs).length + 1))];

	return (
	<>

		<section className="Kabout about spad">
			<div className="container">
				<div className="row">
					<div className="col-lg-4">
						<div className="about__text">
							<h2>
								About {process.env.REACT_APP_SHORTNAME}
							</h2>
							<p>
								<span>{process.env.REACT_APP_PODNAME}: {props.miscInfo['itunes:subtitle'].value}</span>
								<strong style={{color:"#410f57", fontSize:"85%"}} >Pronunciation Note: The French vowel combinations in "eau" (singular) and "eaux" ('plural') are pronounced like the closed O sound - as in the 'O' in "cold."</strong>
								<hr />Published: {props.miscInfo['rawvoice:frequency'].value}<br />

							</p>
						</div>
					</div>
					<div className="col-lg-8">
						<div className="about__item">
							<h6><span><strong>{process.env.REACT_APP_SHORTNAME}</strong> = {props.miscInfo['itunes:keywords'].value}</span></h6>
							<p><strong style={{color:"#410f57", fontSize:"105%"}}>{props.miscInfo.description.value}</strong>
								<br /><br />
								Subscribe:<br />
								<a target="_blank" className="primary-btn" href="https://podcasts.apple.com/us/podcast/the-kneauxla-podcast/id1536703901&amp;ls=1">via iTunes</a>
								<a target="_blank" className="primary-btn white-btn" href="https://podcasts.google.com/feed/aHR0cHM6Ly9rbmVhdXhsYS5jb20vcG9kY2FzdHMvMDAxL2tuZWF1eExhLTAwMS54bWw?sa=X&ved=0CAMQ4aUDahcKEwigkomwirD0AhUAAAAAHQAAAAAQAQ&hl=en">via Google</a>
								


								{ props.getParams.p === undefined || (props.getParams.p !== undefined && props.getParams.p.toLowerCase() !== props.episodesPage.toLowerCase() ) ?
								<>
								<hr />
								<a className="primary-btn Kmore-episodes" href={props.basePage + props.getVar.page + props.episodesPage.toLowerCase()} ><i class="fa fa-search"></i>  Find More Episodes</a>
								</>
								 : "" }


							</p>

						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12" style={{marginBottom:0, }}>
						<div className="about__pic"><img alt="" data-pagespeed-url-hash="1673948906" src="/imgs/buru4.png" /></div>

							<div class="overflow-hidden"  style={{overflow:"hidden"}}>
							  <div class="flex -mx-4 img-ticker">
							  	{props.collageImgs.map((value) => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value).map( (i, k) =>  
									<img class="Kticker w-54 mx-0 self-start flex-none" src={"/featureImgs/collage/" + i +""} />
							  	)}
							  </div>
							</div>
					</div>

				</div>


			</div>

		</section>



	</>
	);
}
/*
						<div className="row" style={{margin:0, minHeight: "75px", }}>
						  	{props.collageImgs.map((value) => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value).map( (i, k) =>  
						  		<div key={k + "_coll"} className="col nopadding" style={{background:"url(/featureImgs/collage/" + i +")",backgroundSize: "contain", backgroundPosition: "top center", backgroundRepeat: "no-repeat"}}>
						  			&nbsp;
						  		</div>
						  	)}
						</div>
*/

									// <img src={"/featureImgs/collage/" + i} className="img-responsive" />

const Blocks = ({props}) => {

	if( (props.getParams.p !== undefined ) && (  props.getParams.p.toLowerCase() === props.episodesPage.toLowerCase()  ) ){
	return (
		<>
			<Pods 		props={props} />
			{/*<Support 	props={props} />*/}
			<About 		props={props} />
			<PodBlocks 	props={props} />
		</>
		);


	}else{
	return (
		<>
			<More 		props={props} />
			<About 		props={props} />
			{/*<Support 	props={props} />*/}
			<PodBlocks 	props={props} />
		</>
		);

	}



}





export default Blocks