const Head = ({props}) => {
	const sitePagesKeysReversed = Object.keys(props.sitePages).reverse();

	return(
		<>
{/*			<div id="preloder" style={{display: "none"}}>
				<div className="loader" style={{display: "none"}}></div>
			</div>
			<div className="offcanvas-menu-overlay"></div>
			<div className="offcanvas-menu-wrapper">
				<div className="offcanvas__logo">
					<a href="./"><img alt="" data-pagespeed-url-hash="3350994549" src={process.env.REACT_APP_LOGOSRC} /></a>
				</div>
				<div id="mobile-menu-wrap">
					<div className="slicknav_menu">
						<a aria-haspopup="true" className="slicknav_btn slicknav_collapsed" href="#" role="button" style={{outline: "currentcolor none medium"}} tabIndex="0"><span className="slicknav_menutxt">MENU</span><span className="slicknav_icon"><span className="slicknav_icon-bar"></span><span className="slicknav_icon-bar"></span><span className="slicknav_icon-bar"></span></span></a>
						<nav aria-hidden="true" className="slicknav_nav slicknav_hidden" role="menu">
							<ul>
								 {props.sitePages.map(pageProps => {
										if( pageProps.uri1.toLowerCase() !== props.podItemPageName ){
											return (
											<li className="active" key={pageProps.uri1 + "_page"}>
												<a href={"/" + pageProps.uri1.toLowerCase()} role="menuitem">-{pageProps.uri1}</a>
											</li>
											);
										}
									}
								  )}

								<li className="slicknav_collapsed slicknav_parent">
									<a aria-haspopup="true" className="slicknav_item slicknav_row" href="#" role="menuitem" style={{outline: "currentcolor none medium"}} tabIndex="-1"></a><a href="#" className="KmobileMenu">{props.podItemPageName}s</a> <span className="slicknav_arrow">►</span>
									<ul aria-hidden="true" className="dropdown slicknav_hidden" role="menu" >
										 {sitePagesKeysReversed.map(p => {
												if( props.sitePages[p].uri1.toLowerCase() === props.podItemPageName ){
													return (
													<li className="active" key={props.sitePages[p].uri2 + "_page"}>
														<a href={"/" + props.sitePages[p].uri1.toLowerCase() + "/" +     props.sitePages[p].uri2.toLowerCase().replace('#', '') + "/" + encodeURI(props.items[props.sitePages[p].key].Kperson).replaceAll("%20", "+")   } role="menuitem">{props.sitePages[p].uri2}: {props.items[props.sitePages[p].key].Kperson}</a>
													</li>
													);
												}
											}
										  )}
									</ul>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				<div className="offcanvas__social">
					<a href="#"><i className="fa fa-facebook"></i></a> <a href="#"><i className="fa fa-twitter"></i></a> <a href="#"><i className="fa fa-pinterest"></i></a> <a href="#"><i className="fa fa-instagram"></i></a> <a href="#"><i className="fa fa-dribbble"></i></a>
				</div>
			</div>
*/}			<header className="header" style={{borderBottom:"0"}}>
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12 text-center">
							<div className="header__logo" style={{marginRight:"0"}}>
								<a href="/"><img alt="" data-pagespeed-url-hash="3350994549" src={props.siteLogo.src2} /></a>
							</div>
						</div>
					</div>
{/*					<div className="canvas__open">
						<i className="fa fa-bars"></i>
					</div>
*/}				</div>
			</header>
		</>
	);

}



const KDate = ({props}) => {
	const date = props;
	return (
		(new Date( date ).toLocaleString([], {year: 'numeric', month: 'long', day: 'numeric', hour12: 'false'})) + " "
	);
}

const PodPlayerHead = ({props, theme}) => {
	const p = props;
	return (
		<>
			<div class="Kiframe-holder">
				<iframe src={p.Kapple + "&theme=dark"} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation" allow=""  style={{width: "100%", maxWidth: "660px",  overflow: "hidden",  borderRadius: "10px",  background: "transparent none repeat scroll 0% 0%", zIndex:"10000"}} height="175px" frameBorder="0"></iframe>
			</div>
		</>	

		);
	// return (
	// 	<>
			
	// 	</>	);
}

const Nav = ({props, currentPage}) => {

	const last 			= Object.keys(props.items).length -1;
	const currentPod 	= (currentPage['uri1'].toLowerCase() === props.podItemPageName.toLowerCase()) ? props.items[currentPage['key']] : props.items[last];
	const date 			= Date.parse( currentPod['pubDate'] );
	const bgImag 		= props.backgrounImgs[props.randomFun(1, (Object.keys(props.backgrounImgs).length + 1))];
	//const beuatyImag 	= "/featureImgs/12/l12-A.jpg";
	//style={{fontSize:"38px", lineHeight:"38px"}}

	return(
		<>
			<section className="hero spad set-bg kpc_bgColor"  data-setbg={"/imgs/" + bgImag} style={{backgroundImage: `url("/imgs/${bgImag}")`, backgroundPosition: "center"}}>
				<div className="container" style={{opacity: 0.9}}>
					<div className="row">
						<div className="col-lg-6">
							<div className="hero__text">
								<h5><span className="icon_calendar"></span> <KDate props={date} /></h5>
								<h2>
									Episode { currentPod['Knumber'] }:<br />{ currentPod['Kperson'] }
								</h2>
								<div className="callto__text">
									<p style={{textAlign: 'left'}}>{ currentPod['itunes:summary'] }</p>
								</div>
{/*
								<a className="primary-btn Kmore-episodes" href={props.basePage + props.getVar.page + props.episodesPage.toLowerCase()} ><i class="fa fa-search"></i>  Find More Episodes</a>
								<a target="_blank" className="primary-btn" href="https://podcasts.apple.com/us/podcast/the-kneauxla-podcast/id1536703901&amp;ls=1">Subscribe with iTunes</a> 
								<a target="_blank" className="primary-btn white-btn" href="https://podcasts.google.com/feed/aHR0cHM6Ly9rbmVhdXhsYS5jb20vcG9kY2FzdHMvMDAxL2tuZWF1eExhLTAwMS54bWw?sa=X&ved=0CAMQ4aUDahcKEwigkomwirD0AhUAAAAAHQAAAAAQAQ&hl=en">Subscribe with Google</a>
*/}							</div>
						</div>
						<div className="col-lg-6" style={{textAlign:"center"}}>

							<div id="carouselExampleInterval" className="carousel slide carousel-fade" data-ride="carousel" style={{overflow:"hidden"}}>
							  <div className="carousel-inner ">


							{ (currentPod['KfeatureImgs'] !== undefined && currentPod['KfeatureImgs'].length > 0 ) ? 
								<>
									<div className="hero__pic set-bg"  style={{justifyContent:"left"}}> 
										{currentPod['KfeatureImgs'].split("|").map( (f, k) => 
										    <div key={k + "carousel"} className={ ( (k === 0) ? "active carousel-item " : " carousel-item  " ) } data-interval="3500">
										      <img src={"/featureImgs/" + currentPod['Knumber'].replace('#', '') + "/" + f} className="img-fluid d-block w-100" alt="..." style={{borderRadius:"5px"}} />
										    </div>

										) } 
									</div>
								</> 
								: ""
							}
							  </div>
							</div>


							<PodPlayerHead props={currentPod} theme={"dark"} />




						</div>
					</div>
				</div>
			</section>
		</>
	);

}
/*

						<div className="col-lg-12">
<div id="carouselExampleInterval" className="carousel slide carousel-fade" data-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active" data-interval="2000">
      <img src="http://localhost:3000/featureImgs/11/11-_0006_5.jpg" className="img-fid d-block w-100" alt="..." />
    </div>
    <div className="carousel-item" data-interval="2000">
      <img src="http://localhost:3000/featureImgs/11/11-_0001_2.jpg" className="img-fluid d-block w-100" alt="..." />
    </div>
    <div className="carousel-item" data-interval="2000">
      <img src="http://localhost:3000/featureImgs/11/11-_0000_1.jpg" className="img-fluid d-block w-100" alt="..." />
    </div>
  </div>
</div>
						</div>





						<div className="col-lg-6">
							{ (currentPod['KfeatureImgs'] !== undefined && currentPod['KfeatureImgs'].length > 0 ) ? 
								<>
									<div className="hero__pic set-bg" style={{ overflow:"hidden", textAlign: "center"}}> 
										{currentPod['KfeatureImgs'].split("|").map( (f, k) => 
											<img key={k + "hero__pic_mont"} className={"u-fadeCycle-" + (k + 1)  + " fadeCycleImg img-fluid"} src={"/featureImgs/" + currentPod['Knumber'].replace('#', '') + "/" + f} />
										) } 
									</div>
									<br />
								</> 
								: ""
							}
*/



const NavEpisodes = ({props, currentPage}) => {

	const bgImag 		= props.backgrounImgs[props.randomFun(1, (Object.keys(props.backgrounImgs).length + 1))];
	return(
		<>

		<section className="breadcrumb-option spad set-bg kpc_bgColor" data-setbg={"/imgs/" + bgImag} style={{backgroundImage: `url("/imgs/${bgImag}")`, backgroundPosition: "center"}}>
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="breadcrumb__text">
							<h2>{process.env.REACT_APP_PODNAMEALT}  Episodes</h2>
							<div className="breadcrumb__links">
								<a href="/"><i className="fa fa-home"></i> Home</a> <span>{props.episodesPage}</span>
							</div>
						</div>
					</div>
				</div>
				<br /><br /><br /><br /><br />
			</div>
		</section>
		</>
	);

}



const Header = ({props, currentPage}) => {

	if( (props.getParams.p !== undefined ) && (  props.getParams.p.toLowerCase() === props.episodesPage.toLowerCase()  ) ){
		return (
			<>
				<Head props={props} />
				<NavEpisodes props={props} currentPage={currentPage} />
			</>
			);


	}else{
		return (
			<>
				<Head props={props} />
				<Nav props={props} currentPage={currentPage} />
			</>
			);

	}

}





export default Header