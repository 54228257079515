const Footer = ({props}) => {
	const bgImag 		= "kneauxla-og.jpg";//props.backgrounImgs[props.randomFun(1, (Object.keys(props.backgrounImgs).length + 1))];

	return (
		<>
			<footer className="footer set-bg kpc_bgColorFooter" data-setbg={"/imgs/" + bgImag} style={{backgroundImage: `url("/imgs/${bgImag}")`, backgroundPosition: "bottom"}}>
				<div className="container">
					<div className="footer__subscriber" style={{display:"none"}}>
						<div className="row">
							<div className="col-lg-5">
								<div className="footer__subscriber__text">
									<h3>Sign up for our newsletter!</h3>
									<p>Subscribe to receive info on our latest news and episodes</p>
								</div>
							</div>
							<div className="col-lg-5 offset-lg-2">
								<form action="#" className="footer__subscriber__form">
									<input placeholder="Your email address" type="text" /> <button className="site-btn" type="submit">Subscribe</button>
								</form>
							</div>
						</div>
					</div>
					<div className="footer__subscriber">
						<div className="row">
							<div className="col-lg-7">
								<div className="footer__subscriber__text">
									<h3>Subscribe to {process.env.REACT_APP_SHORTNAME}!</h3>
									<p>Subscribe to receive get the next episode of {process.env.REACT_APP_PODNAME} </p>
								</div>
							</div>
							<div className="col-lg-5 text-right" style={{textAlign:"right"}}>
								<a target="_blank" className="primary-btn" href="https://podcasts.apple.com/us/podcast/the-kneauxla-podcast/id1536703901&amp;ls=1">via iTunes</a> 
								<a target="_blank" className="primary-btn white-btn" href="https://podcasts.google.com/feed/aHR0cHM6Ly9rbmVhdXhsYS5jb20vcG9kY2FzdHMvMDAxL2tuZWF1eExhLTAwMS54bWw?sa=X&ved=0CAMQ4aUDahcKEwigkomwirD0AhUAAAAAHQAAAAAQAQ&hl=en">via Google</a>

							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-9 col-md-9">
							<div className="footer__widget">
								<div className="footer__logo">
									<a href="/"><img alt="" style={{width:"75%"}} data-pagespeed-url-hash="3350994549"  src={process.env.REACT_APP_LOGOSRCB} /></a>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3">
							<div className="footer__social" style={{display:"none"}}>
								<a href="#"><i className="fa fa-facebook"></i></a> <a href="#"><i className="fa fa-twitter"></i></a> <a href="#"><i className="fa fa-pinterest"></i></a> <a href="#"><i className="fa fa-instagram"></i></a> <a href="#"><i className="fa fa-youtube-play"></i></a>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 col-md-12">
							<p className="footer__copyright__text">
								© {(new Date().getFullYear())} | {process.env.REACT_APP_COMPANY} | All Rights Reserved. 
							</p>
						</div>
					</div>
				</div>
			</footer>

		</>
		);
}

export default Footer