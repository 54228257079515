import React from 'react';
import './App.css';
import Header from './Header.js'
import Footer from './Footer.js'
import Blocks from './Blocks.js'
//import HeadElements from './HeadElements.js'
import { Link } from 'react-router';

import SetupProps from "./Setup"


function Preloader() {

  const preloadImages = [];
  for (let i = 0; i < Object.keys(SetupProps.backgrounImgs).length; i++) {
    preloadImages.push( SetupProps.backgrounImgs[1] );
  } 

  return (
    <>
      <div id="preloader"> 
          { preloadImages.map(i => 
              <img src={"/imgs/" + i} width="1" height="1" />
            )
          }
      </div>
    </>
  );


}

function App() {

  const uri = window.location.href.split('/');
  //console.log(uri) 
  //console.log(SetupProps) 

  let selectedPage = SetupProps.sitePages[0];
  SetupProps.sitePages.map(pageProps => {
    //console.log(uri);
      if( ( SetupProps.getParams.p !== undefined ) ){
        if( SetupProps.getParams.p.toLowerCase() == SetupProps.podItemPageName.toLowerCase() ){
          if( pageProps.uri2.replace('#', '').toLowerCase() === SetupProps.getParams.e.toLowerCase() ){
            selectedPage = pageProps;
          }
        }else{
          selectedPage = pageProps;
        }
      }
    }
  );

  // let selectedPage = SetupProps.sitePages[0];
  // SetupProps.sitePages.map(pageProps => {
  //   //console.log(uri);
  //     if( uri[4] === "#" && (pageProps.uri1.toLowerCase() === uri[5].toLowerCase()) ){
  //       if( uri[5].toLowerCase() == SetupProps.podItemPageName.toLowerCase() ){
  //         if( pageProps.uri2.replace('#', '').toLowerCase() === uri[6].toLowerCase() ){
  //           selectedPage = pageProps;
  //         }
  //       }else{
  //         selectedPage = pageProps;
  //       }
  //     }
  //   }
  // );


        //console.log(selectedPage);
        //<HeadElements />

  return (
    <>
        <Preloader />
        <Header props={SetupProps} currentPage={selectedPage} />
        <Blocks props={SetupProps} currentPage={selectedPage} />
        <Footer props={SetupProps} currentPage={selectedPage} />
    </>
  );
}

export default App;